
import { Container } from "reactstrap";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { unsubscribeRequest } from "../../../api/controller/NewsletterController";

const Unsubscribe = () => {

    const params = useParams();
    const [text, setText] = useState("Unsubscribing")

    

    useEffect(() => {
        const unsubFn = async() => {
            const response = await unsubscribeRequest({ Ref: params.ref });
            if(response && response.Status){
                setText(response.Message)
            } else {
                setText("Something went wrong!")
            }
        }
        if(params.ref){
            unsubFn()
        }
    }, [params.ref])

    return (
        <React.Fragment>
                <Container fluid>
                    <h3 align="center" className="mt-5 pt-5">{text}</h3>
                </Container>
        </React.Fragment>
        
    )
    
}

export default Unsubscribe;

