
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useMemo, useState } from "react";
import { downloadCompilationRequest, getCompilationFixedRequest, getCompilationPerXRequest } from "../../../api/controller/StatisticsController";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import { Loader } from "../../../custom/components/Loader";

const ArrivalRevenue = () => {

    const [loading, setLoading] = useState(true)
    const [mainData, setMainData] = useState([])
    const [years, setYears] = useState([])

    const [selectedYears, setSelectedYears] = useState([])
    
    const [metrics] = useState([
        {label: "Tourist Arrivals", value: "TouristArrival"},
        {label: "Travel Receipts", value: "TravelReceipt"},
        {label: "Green Tax", value: "GreenTax"},
        {label: "TGST", value: "TGST"},
        {label: "Bed Capacity", value: "BedCapacity"},
        {label: "Average Stay", value: "AverageStay"},
        {label: "Occupancy Rate", value: "OccupancyRate"},
        {label: "Bed Nights", value: "BedNight"},
        {label: "Travel Receipts Per Tourist", value: "TravelReceiptPerTourist"},
        {label: "Green Tax Per Tourist", value: "GreenTaxPerTourist"},
        {label: "TGST Per Tourist", value: "TGSTPerTourist"},
        {label: "Travel Receipts Per Bed", value: "TravelReceiptPerBed"},
        {label: "Green Tax Per Bed", value: "GreenTaxPerBed"},
        {label: "TGST Per Bed", value: "TGSTPerBed"},
    ])
    const [selectedMetric, setSelectedMetric] = useState({label: "Tourist Arrivals", value: "TouristArrival"})
    const [seriesData, setSeriesData] = useState([])
    const [categories] = useState(["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"])
    const [fullMonths] = useState(["JANUARY","FEBRUARY","MARCH","APRIL","MAY","JUNE","JULY","AUGUST","SEPTEMBER","OCTOBER", "NOVEMBER", "DECEMBER"])

    const mappings = useMemo(() => ({
        TouristArrival: { fn: getCompilationFixedRequest, header: "Tourist Arrivals", yHeader: "", yFormatter: (val) => val.toLocaleString() },
        TravelReceipt: { fn: getCompilationFixedRequest, header: "Total Travel Receipts - US$", yHeader: "$ (millions)", yFormatter: (val) => (val / 1000000).toLocaleString() },
        GreenTax: { fn: getCompilationFixedRequest, header: "Green Tax - US$", yHeader: "$ (millions)", yFormatter: (val) => (val / 1000000).toLocaleString() },
        TGST: { fn: getCompilationFixedRequest, header: "TGST - US$", yHeader: "$ (millions)", yFormatter: (val) => (val / 1000000).toLocaleString() },
        BedCapacity: { fn: getCompilationFixedRequest, header: "Bed Capacity", yHeader: "", yFormatter: (val) => val.toLocaleString() },
        AverageStay: { fn: getCompilationFixedRequest, header: "Average Stay", yHeader: "", yFormatter: (val) => val.toLocaleString() },
        OccupancyRate: { fn: getCompilationFixedRequest, header: "Occupancy Rate", yHeader: "", yFormatter: (val) => val.toLocaleString() },
        BedNight: { fn: getCompilationFixedRequest, header: "Bed Nights", yHeader: "", yFormatter: (val) => val.toLocaleString() },
        TravelReceiptPerTourist: { fn: getCompilationPerXRequest, X: 'TouristArrival', header: "Travel Receipts Per Tourist - US$", yHeader: "$", yFormatter: (val) => val.toLocaleString() },
        GreenTaxPerTourist: { fn: getCompilationPerXRequest, X: 'TouristArrival', header: "Green Tax Per Tourist - US$", yHeader: "$", yFormatter: (val) => val.toLocaleString() },
        TGSTPerTourist: { fn: getCompilationPerXRequest, X: 'TouristArrival', header: "TGST Per Tourist - US$", yHeader: "$", yFormatter: (val) => val.toLocaleString() },
        TravelReceiptPerBed: { fn: getCompilationPerXRequest, X: 'BedCapacity', header: "Travel Receipts Per Bed - US$", yHeader: "$", yFormatter: (val) => val.toLocaleString() },
        GreenTaxPerBed: { fn: getCompilationPerXRequest, X: 'BedCapacity', header: "Green Tax Per Bed - US$", yHeader: "$", yFormatter: (val) => val.toLocaleString() },
        TGSTPerBed: { fn: getCompilationPerXRequest, X: 'BedCapacity', header: "TGST Per Bed - US$", yHeader: "$", yFormatter: (val) => val.toLocaleString() },
    }), [])


    useEffect(() => {
        async function getData(){
            setLoading(true)
            setMainData([])
            setYears([])
            const response = await mappings[selectedMetric.value].fn({ Metric: selectedMetric.value.replace("PerTourist","").replace("PerBed",""), X: mappings[selectedMetric.value].X });
            if(response && response.mainData) setMainData(response.mainData);
            if(response && response.years) setYears(response.years);
            setLoading(false)
        }
        getData();
    }, [mappings, selectedMetric.value])

    useEffect(() => {
        setSelectedYears(years.map(y=>({label: y, value: y})));
    }, [years])

    useEffect(() => {
        if(Object.keys(mainData).length > 0 && selectedYears.length > 0 && selectedMetric.value){
            let data = []
            selectedYears?.forEach((y)=>{
                let tempData = Array(12).fill(0)
                mainData[y.value].forEach((d)=>{
                    tempData[d.month-1] = d.tv
                });
                data.push({name: y.label, data: tempData})
            })
            setSeriesData(data)
        } else if (selectedYears.length === 0){
            setSeriesData([])
        }
    }, [selectedYears, selectedMetric, mainData])


    const options = {
        chart: {
            toolbar: { show: false, },
        },
        plotOptions: {
            bar: { horizontal: false, columnWidth: "45%", endingShape: "rounded" },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#34c38f", "#556ee6", "#f46a6a", "#f1c40f", "#3498db", "#1abc9c", "#9b59b6", "#e74c3c", "#2ecc71", "#34495e"],
        xaxis: {
            categories: categories,
        },
        yaxis: { 
            title: { text: mappings[selectedMetric.value].yHeader, }, 
            labels: {
                formatter: mappings[selectedMetric.value].yFormatter
            }
        },
        grid: { borderColor: "#f1f1f1", },
        fill: { opacity: 1, },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val.toLocaleString();
                },
            },
        },
    };

    const [downloading, setDownloading] = useState(false)

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Loader loading={loading} size="top" />
                                    <Row className="mb-2">
                                        <Col xs={2}></Col>
                                        <Col xs={8}><h3 align="center">{mappings[selectedMetric.value].header}</h3></Col>
                                        <Col xs={2} align="right">
                                            <Button disabled={downloading} color='info' title="Download" className='ml-2 waves-effect waves-light' type='submit' onClick={async() => {
                                                setDownloading(true)
                                                await downloadCompilationRequest()
                                                setDownloading(false)
                                            }}>
                                                <i className='mdi mdi-download d-block font-size-12'></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Select options={metrics} value={selectedMetric} classNamePrefix="select2-selection" isClearable={false} onChange={(e)=>setSelectedMetric({label: e.label, value: e.value})} />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Select options={years.map(y=>({label: y, value: y}))} value={selectedYears} classNamePrefix="select2-selection" isMulti={true} isClearable={false} onChange={(e)=>setSelectedYears(e.map((val)=>({label: val?.label, value: val?.value})))} />
                                        </Col>
                                    </Row>
                                    <ReactApexChart options={options} series={seriesData} type="bar" height={650} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Loader loading={loading} size="top" />
                                        <h3 align="center">{mappings[selectedMetric.value].header}</h3>
                                        <div className="table-responsive">
                                            <Table className="custom-table table table mb-0" style={{textAlign: 'center'}}>
                                                <thead>
                                                    <tr className="ct-header">
                                                        <th>#</th>
                                                        <th key="operator">Month</th>
                                                        {(years.map((y,k)=>(<><th key={y}>{y}</th>{k+1 < years.length ? <th key={y+k}>VARIANCE</th> : null}</>)))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array(12).fill(0).map((_,i)=>(
                                                        <tr key={i}>
                                                            <td>{i+1}</td>
                                                            <td>{fullMonths[i]}</td>
                                                            {years.map((y, j)=>(
                                                                <>
                                                                    <td key={j}>{mainData[y]?.find((d)=>parseInt(d.month) === (i+1))?.tv ? parseInt(mainData[y]?.find((d)=>parseInt(d.month) === (i+1))?.tv)?.toLocaleString() : "-"}</td>
                                                                    {j+1 < years.length ? (
                                                                        <td key={j+1}>
                                                                            {mainData[y]?.find((d)=>parseInt(d.month) === (i+1))?.tv > 0 && mainData[years[j+1]]?.find((d)=>parseInt(d.month) === (i+1))?.tv > 0 ? 
                                                                            <span style={{color: (((parseInt(mainData[years[j+1]]?.find((d)=>parseInt(d.month) === (i+1))?.tv) - parseInt(mainData[y]?.find((d)=>parseInt(d.month) === (i+1))?.tv)) / parseInt(mainData[y]?.find((d)=>parseInt(d.month) === (i+1))?.tv))*100) < 0 ? "#ad1328" : "#29a616"}}>
                                                                                {(((parseInt(mainData[years[j+1]]?.find((d)=>parseInt(d.month) === (i+1))?.tv) - parseInt(mainData[y]?.find((d)=>parseInt(d.month) === (i+1))?.tv)) / parseInt(mainData[y]?.find((d)=>parseInt(d.month) === (i+1))?.tv))*100).toFixed(1)}
                                                                                %
                                                                                <span className={"mdi mdi-trending-"+((((parseInt(mainData[years[j+1]]?.find((d)=>parseInt(d.month) === (i+1))?.tv) - parseInt(mainData[y]?.find((d)=>parseInt(d.month) === (i+1))?.tv)) / parseInt(mainData[y]?.find((d)=>parseInt(d.month) === (i+1))?.tv))*100) < 0 ? "down" : 
                                                                                    ((((parseInt(mainData[years[j+1]]?.find((d)=>parseInt(d.month) === (i+1))?.tv) - parseInt(mainData[y]?.find((d)=>parseInt(d.month) === (i+1))?.tv)) / parseInt(mainData[y]?.find((d)=>parseInt(d.month) === (i+1))?.tv))*100) === 0 ? "neutral" : "up"))}></span>
                                                                            </span>
                                                                            : "-"}
                                                                        </td>
                                                                    ) : null}
                                                                </>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default ArrivalRevenue;

