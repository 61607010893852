import http from "../core/http";
const group = 'home';

export const getDashboardRequest =  async (data) => {
    return await http.post(`${group}/getDashboard`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getYearlyArrivalsRequest =  async (data) => {
    return await http.post(`${group}/getYearlyArrivals`, data).then(res => res.data).catch(http.defaultCatch);
}
