import { cloneDeep } from "lodash";
import { atom, selector } from "recoil";
import { authState, permissionState } from "../api/state/AuthState";
import Login from "../app/guest/Login";
import ForgotPassword from "../app/guest/ForgotPassword";
import ChangePassword from "../app/guest/ChangePassword";
import Register from "../app/guest/Register";
import Home from "../app/auth/Home";
import Users from "../app/auth/users"
import Roles from "../app/auth/roles"
import Permissions from "../app/auth/permissions"
import NonAuthLayout from "../components/NonAuthLayout";
import BedNights from "../app/auth/bedNights"
import OccupancyRates from "../app/auth/occupancyRates"
import AverageStays from "../app/auth/averageStays"
import AnnualReports from "../app/auth/annualReports"
import Barometers from "../app/auth/barometers"
import Emails from "../app/auth/emails"
import ManageArticles from "../app/auth/manageArticles"
import ContactGroups from "../app/auth/contactGroups"
import Contacts from "../app/auth/contacts"
import ManageNewsletters from "../app/auth/manageNewsletters"

import MarketArrivals from "../app/auth/marketArrivals"
import Markets from "../app/auth/markets"
import TouristArrivals from "../app/auth/touristArrivals"
import BedCapacities from "../app/auth/bedCapacities"
import TravelReceipts from "../app/auth/travelReceipts"
import OperatorArrivals from "../app/auth/operatorArrivals"
import MaclArrivals from "../app/auth/maclArrivals"
import Operators from "../app/auth/operators"
import Taxes from "../app/auth/taxes"
import Macl from "../app/auth/statistics/macl";
import Tax from "../app/auth/statistics/tax";
import Bed from "../app/auth/statistics/bed";
import ArrivalRevenue from "../app/auth/statistics/arrivalRevenue";
import ArrivalsByMarket from "../app/auth/resources/arrivalsByMarket";
import AirTrafficMovements from "../app/auth/resources/airTrafficMovements";
import MiraRevenue from "../app/auth/resources/miraRevenue";

import Newsletters from "../app/auth/newsletters"
import Newsletter from "../app/auth/newsletters/newsletter"
import Unsubscribe from "../app/auth/newsletters/unsubscribe";



// import HorizontalLayout from "../components/HorizontalLayout";
// import GuestHome from "../app/guest/Home";


const navigation = [
    {
        name: 'Login',
        icon: 'bx bx-lock',
        link: '/login',
        defaultRoute: true,
        component: Login,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Forgot Password',
        icon: 'bx bx-lock',
        link: '/forgot-password',
        component: ForgotPassword,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Change Password',
        icon: 'bx bx-lock',
        link: '/change-password',
        component: ChangePassword,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Register',
        icon: 'bx bx-lock',
        link: '/register',
        component: Register,
        layout: NonAuthLayout,
        middleware: 'guest',
        hidden: true,
        children: [],
    },
    {
        name: 'Home',
        icon: 'mdi mdi-view-dashboard',
        link: '/home',
        defaultRoute: true,
        component: Home,
        middleware: 'auth',
        children: [],
    },
	{
        name: 'Main',
        icon: "bx bx-group",
        children: [
            {
                name: 'Statistics',
                icon: "bx bx-stats",
                children: [
                    {
                        name: 'Tourism Overview',
                        icon: 'mdi mdi-cash-multiple',
                        link: '/arrvalVsRevenue',
                        component: ArrivalRevenue,
                        middleware: 'auth',
                        children: [],
                    },
                    {
                        name: 'Arrivals by Markets',
                        icon: 'mdi mdi-map',
                        link: '/arrivalsByMarkets',
                        component: ArrivalsByMarket,
                        middleware: 'auth',
                        children: [],
                    },
                    {
                        name: 'Bed Capacity (breakdown)',
                        icon: 'mdi mdi-bed-empty',
                        link: '/beds',
                        component: Bed,
                        middleware: 'auth',
                        children: [],
                    },
                    {
                        name: 'Aviation',
                        icon: 'mdi mdi-airport',
                        link: '/macl',
                        component: Macl,
                        middleware: 'auth',
                        children: [],
                    },                    
                    {
                        name: 'Aviation (breakdown)',
                        icon: 'mdi mdi-radar',
                        link: '/airTrafficMovements',
                        component: AirTrafficMovements,
                        middleware: 'auth',
                        children: [],
                    },
                    {
                        name: 'Tax Collection Overview',
                        icon: 'mdi mdi-cash',
                        link: '/mira',
                        component: Tax,
                        middleware: 'auth',
                        children: [],
                    },
                    {
                        name: 'Tax Collection (monthly breakdown)',
                        icon: 'mdi mdi-currency-eur',
                        link: '/miraRevenue',
                        component: MiraRevenue,
                        middleware: 'auth',
                        children: [],
                    },
                ]
            },
            // {
            //     name: 'Resources',
            //     icon: "mdi mdi-semantic-web",
            //     children: [
            //         {
            //             name: 'Arrivals by Markets',
            //             icon: 'mdi mdi-map',
            //             link: '/arrivalsByMarkets',
            //             component: ArrivalsByMarket,
            //             middleware: 'auth',
            //             children: [],
            //         },
            //         {
            //             name: 'Air Traffic Movements',
            //             icon: 'mdi mdi-radar',
            //             link: '/airTrafficMovements',
            //             component: AirTrafficMovements,
            //             middleware: 'auth',
            //             children: [],
            //         },
            //         {
            //             name: 'MIRA Revenue',
            //             icon: 'mdi mdi-currency-eur',
            //             link: '/miraRevenue',
            //             component: MiraRevenue,
            //             middleware: 'auth',
            //             children: [],
            //         },
            //     ]
            // },
            // {
            //     name: 'Newsletter',
            //     icon: 'mdi mdi-email-newsletter',
            //     link: '/newsletters',
            //     component: Newsletters,
            //     middleware: 'auth',
            //     children: [],
            // },
            {
                name: 'Newsletters',
                icon: 'mdi mdi-email-newsletter',
                link: '/newsletter/:newsletter',
                component: Newsletter,
                layout: NonAuthLayout,
                hidden: true,
                children: [],
            },
            {
                name: 'Unsubscribe',
                icon: 'mdi mdi-email-newsletter',
                link: '/unsubscribe/:ref',
                component: Unsubscribe,
                layout: NonAuthLayout,
                hidden: true,
                children: [],
            },
            {
                name: 'UNWTO Barometer',
                icon: 'mdi mdi-gauge',
                link: '/barometers',
                component: Barometers,
                middleware: 'auth',
                children: [],
            },
            { // AnnualReport Route Begin
                name: 'Annual Report',
                icon: 'mdi mdi-file-chart-outline',
                link: '/annualReports',
                component: AnnualReports,
                middleware: 'auth',
                children: [],
            }, // AnnualReport Route End
        ]
    },
    {
        name: 'Administration',
        icon: "bx bx-group",
        children: [
            
            {
                name: 'Data',
                icon: "bx bx-data",
                children: [
                    { // Tax Route Begin
                        name: 'Tax',
                        icon: 'mdi mdi-currency-usd-off',
                        link: '/taxes',
                        component: Taxes,
                        middleware: 'auth',
                        permission: 'read-taxes',
                        children: [],
                    }, // Tax Route End
                    { // TouristArrival Route Begin
                        name: 'Tourist Arrival',
                        icon: 'mdi mdi-bag-personal-outline',
                        link: '/touristArrivals',
                        component: TouristArrivals,
                        middleware: 'auth',
                        permission: 'read-touristArrivals',
                        children: [],
                    }, // TouristArrival Route End
                    { // MaclArrival Route Begin
                        name: 'MACL Arrival',
                        icon: 'mdi mdi-airplane-landing',
                        link: '/maclArrivals',
                        component: MaclArrivals,
                        middleware: 'auth',
                        permission: 'read-maclArrivals',
                        children: [],
                    }, // MaclArrival Route End
                    { // OperatorArrival Route Begin
                        name: 'Operator Arrival',
                        icon: 'mdi mdi-airplane',
                        link: '/operatorArrivals',
                        component: OperatorArrivals,
                        middleware: 'auth',
                        permission: 'read-operatorArrivals',
                        children: [],
                    }, // OperatorArrival Route End
                    { // MarketArrival Route Begin
                        name: 'Market Arrival',
                        icon: 'mdi mdi-map-legend',
                        link: '/marketArrivals',
                        component: MarketArrivals,
                        middleware: 'auth',
                        permission: 'read-marketArrivals',
                        children: [],
                    }, // MarketArrival Route End
                    { // TravelReceipt Route Begin
                        name: 'Travel Receipt',
                        icon: 'mdi mdi-receipt',
                        link: '/travelReceipts',
                        component: TravelReceipts,
                        middleware: 'auth',
                        permission: 'read-travelReceipts',
                        children: [],
                    }, // TravelReceipt Route End
                    { // BedCapacity Route Begin
                        name: 'Bed Capacity',
                        icon: 'mdi mdi-bed-empty',
                        link: '/bedCapacities',
                        component: BedCapacities,
                        middleware: 'auth',
                        permission: 'read-bedCapacities',
                        children: [],
                    }, // BedCapacity Route End
                    { // AverageStay Route Begin
                        name: 'Average Stay',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/averageStays',
                        component: AverageStays,
                        middleware: 'auth',
                        permission: 'read-averageStays',
                        children: [],
                    }, // AverageStay Route End
                    { // OccupancyRate Route Begin
                        name: 'Occupancy Rate',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/occupancyRates',
                        component: OccupancyRates,
                        middleware: 'auth',
                        permission: 'read-occupancyRates',
                        children: [],
                    }, // OccupancyRate Route End
                    { // BedNight Route Begin
                        name: 'Bed Night',
                        icon: 'mdi mdi-monitor-dashboard',
                        link: '/bedNights',
                        component: BedNights,
                        middleware: 'auth',
                        permission: 'read-bedNights',
                        children: [],
                    }, // BedNight Route End
                ]
            },
            {
                name: 'Manage Newsletter',
                icon: "bx bx-news",
                children: [
                    
                    { // Newsletter Route Begin
                        name: 'Manage Newsletter',
                        icon: 'mdi mdi-email-newsletter',
                        link: '/manageNewsletters',
                        component: ManageNewsletters,
                        middleware: 'auth',
                        permission: 'read-newsletters',
                        children: [],
                    }, // Newsletter Route End
                    { // Article Route Begin
                        name: 'Manage Articles',
                        icon: 'mdi mdi-post',
                        link: '/manageArticles',
                        component: ManageArticles,
                        middleware: 'auth',
                        permission: 'read-articles',
                        children: [],
                    }, // Article Route End
                ]
            },
            {
                name: 'Email Center',
                icon: "mdi mdi-email",
                children: [
                    { // Email Route Begin
                        name: 'Email',
                        icon: 'mdi mdi-email',
                        link: '/emails',
                        component: Emails,
                        middleware: 'auth',
                        permission: 'read-emails',
                        children: [],
                    }, // Email Route End
                    { // Contact Route Begin
                        name: 'Contact',
                        icon: 'mdi mdi-card-account-mail-outline',
                        link: '/contacts',
                        component: Contacts,
                        middleware: 'auth',
                        permission: 'read-contacts',
                        children: [],
                    }, // Contact Route End
                    { // ContactGroup Route Begin
                        name: 'Contact Group',
                        icon: 'mdi mdi-account-group',
                        link: '/contactGroups',
                        component: ContactGroups,
                        middleware: 'auth',
                        permission: 'read-contactGroups',
                        children: [],
                    }, // ContactGroup Route End
                ]
            },
            {
                name: 'Master',
                icon: "mdi mdi-shield-airplane-outline",
                children: [
                    { // Operator Route Begin
                        name: 'Operator',
                        icon: 'mdi mdi-shield-airplane-outline',
                        link: '/operators',
                        component: Operators,
                        middleware: 'auth',
                        permission: 'read-operators',
                        children: [],
                    }, // Operator Route End
                    { // Market Route Begin
                        name: 'Market',
                        icon: 'mdi mdi-map-marker',
                        link: '/markets',
                        component: Markets,
                        middleware: 'auth',
                        permission: 'read-markets',
                        children: [],
                    }, // Market Route End
                ]
            },
	// Navigations
            
            {
                name: 'ACL',
                icon: "bx bx-group",
                children: [
                    {
                        name: 'Users',
                        icon: 'bx bx-group',
                        link: '/acl/users',
                        component: Users,
                        middleware: 'auth',
                        permission: 'read-users',
                        children: []
                    },
                    {
                        name: 'Roles',
                        icon: 'mdi mdi-account-key-outline',
                        link: '/acl/roles',
                        component: Roles,
                        middleware: 'auth',
                        permission: 'read-roles',
                        children: []
                    },
                    {
                        name: 'Permissions',
                        icon: 'bx bx-lock-open-alt',
                        link: '/acl/permissions',
                        component: Permissions,
                        middleware: 'auth',
                        permission: 'read-permissions',
                        children: []
                    },
                ]
            },
        ]
    }
    
	
    
];


const megaMenu = [
    // {
    //     name: 'UI Components',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Lightbox',
    //             link: '/lightbox',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Range Slider',
    //             link: '/rangeslider',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // },
    // {
    //     name: 'Applications',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Ecommerce',
    //             link: '/ecommerce',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Calendar',
    //             link: '/calendar',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // },
    // {
    //     name: 'Extra Pages',
    //     size: 4,
    //     children: [
    //         {
    //             name: 'Maintenance',
    //             link: '/maintenance',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //         {
    //             name: 'Coming Soon',
    //             link: '/comingsoon',
    //             component: GuestHome,
    //             middleware: 'guest',
    //         },
    //     ]
    // }
]

const rawNavigationState = atom({
    key: 'rawNavigationState',
    default: navigation,
});


const rawMegaMenuState = atom({
    key: 'rawMegaMenuState',
    default: megaMenu,
});





export const navigationState = selector({
    key: 'navigationState',
    get: ({ get }) => {
        let tempNavigation = cloneDeep(get(rawNavigationState))
        const isAuth = get(authState)
        const permissions = get(permissionState)
        const parseModule = (nav) => {
            let returnVal = null
            if(nav.children.length > 0){
                if((isAuth && nav.middleware === "auth" && (!nav.permission || permissions.includes(nav.permission))) || (!isAuth && (nav.middleware === "guest" )) || !nav.middleware){
                    let tempChildren = []
                    nav.children.forEach((child)=>{
                        let temp = parseModule(child)
                        if(temp){
                            tempChildren = [...tempChildren, temp]
                        }
                    })
                    returnVal = {...nav, children: tempChildren}
                }
            } else {
                if((isAuth && nav.middleware === "auth" && (!nav.permission || permissions.includes(nav.permission))) || (!isAuth && (nav.middleware === "guest")) || !nav.middleware){
                    returnVal = nav
                }
            }
            return returnVal
        }
        // Remove Nav Items based on auth and permissions
        tempNavigation = tempNavigation.map((header)=>parseModule(header)).filter(n=>n!==null);
        // Remove Empty Parents
        const filterChildren = (nav) => {
            if(nav.children.filter(c=>c.children.length > 0).length > 0){
                nav.children = nav.children.map((child)=>{
                    child = filterChildren(child)
                    return child
                })
            } else {
                nav.children = nav.children.filter(c=>(c.children.length > 0 || c.component))
            }
            return nav
        }
        tempNavigation = tempNavigation.map((header)=>filterChildren(header));
        // Remove Empty Modules
        tempNavigation = tempNavigation.filter(header=>(header.children.length > 0 || header.component))
        return tempNavigation;
    }
})

export const megaMenuState = selector({
    key: 'megaMenuState',
    get: ({ get }) => {
        let tempMegaMenu = cloneDeep(get(rawMegaMenuState))
        const isAuth = get(authState)
        const permissions = get(permissionState)
        tempMegaMenu = tempMegaMenu.map((header)=>{
            header.children = header.children.filter(c=>(isAuth && c.middleware === "auth" && (!c.permission || permissions.includes(c.permission))) || (!isAuth && (c.middleware === "guest")) || !c.middleware)
            return header
        }).filter(header=>header.children.length > 0);
        return tempMegaMenu;
    }
})
