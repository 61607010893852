
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { getDashboardRequest, getYearlyArrivalsRequest } from "../../api/controller/HomeController";
import { Loader } from "../../custom/components/Loader";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import worldMap from "../../assets/images/world_map.svg";

const Home = () => {

    const [selectedMarketYear, setSelectedMarketYear] = useState('')
    const [selectedChartYears, setSelectedChartYears] = useState([])
    const [years, setYears] = useState([])

    const [marketLoading, setMarketLoading] = useState(true)
    const [chartLoading, setChartLoading] = useState(true)

    const [yearlyArrivals, setYearlyArrivals] = useState([])
    const [seriesData, setSeriesData] = useState([])

    const [marketArrivals, setMarketArrivals] = useState([])
    const [totalArrivals, setTotalArrivals] = useState(null)
    const [totalReceipts, setTotalReceipts] = useState(null)
    const [totalReceiptsPerTourist, setTotalReceiptsPerTourist] = useState(null)

    useEffect(() => {
        async function getYearArrivalsData(){
            setChartLoading(true)
            const response = await getYearlyArrivalsRequest()
            if(response && response.yearlyArrivals){
                setYearlyArrivals(response.yearlyArrivals)
                setYears(response.years.map(y=>({label: y, value: y})))
                setSelectedMarketYear({label: response.years[0], value: response.years[0]})
                setSelectedChartYears(response.years.map(y=>({label: y, value: y})))
            }
            setChartLoading(false)
        }
        getYearArrivalsData()
    }, [])

    useEffect(() => {
        async function getMarketData(){
            setMarketLoading(true)
            const response = await getDashboardRequest({Year: selectedMarketYear.value})
            if(response && response.marketArrivals){
                setMarketArrivals(response.marketArrivals)
                setTotalArrivals(response.totalArrivals)
                setTotalReceipts(response.totalReceipts)
                setTotalReceiptsPerTourist(response.totalReceiptsPerTourist)
            }
            setMarketLoading(false)
        }
        if(selectedMarketYear){
            getMarketData()
        } else {
            setMarketLoading(false)
        }
    }, [selectedMarketYear])

    useEffect(() => {
        if(Object.keys(yearlyArrivals).length > 0){
            let data = []
            selectedChartYears?.forEach((y)=>{
                let tempData = Array(12).fill(0)
                yearlyArrivals[y.value].forEach((d)=>{
                    tempData[d.month-1] = d.total_arrival
                });
                data.push({name: y.label, data: tempData})
            })
            setSeriesData(data)
        }
    }, [selectedChartYears, yearlyArrivals])

    const options = {
        chart: {
            toolbar: { show: false, },
        },
        plotOptions: {
            bar: { horizontal: false, columnWidth: "45%", endingShape: "rounded" },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#34c38f", "#556ee6", "#f46a6a", "#f1c40f", "#3498db", "#1abc9c", "#9b59b6", "#e74c3c", "#2ecc71", "#34495e"],
        xaxis: {
            categories: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL" , "AUG", "SEP", "OCT", "NOV", "DEC"],
        },
        yaxis: { title: { text: "Arrivals", }, },
        grid: { borderColor: "#f1f1f1", },
        fill: { opacity: 1, },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val.toLocaleString() + " Arrivals";
                },
            },
        },
        legend: {
            onClick: () => {
                return false;
            }
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Loader loading={marketLoading} size="top" />
                                    <h3 align="center">TOP 10 MARKETS</h3>
                                    <Select options={years} value={selectedMarketYear} classNamePrefix="select2-selection" isClearable={false} onChange={(e)=>setSelectedMarketYear({label: e.label, value: e.value})} />
                                    <Row className="mt-2">
                                        <Col xs={12} md={9} align="center">
                                            <Row>
                                                <Col xs={12} md={2}>
                                                    {/* first 5 market arrivals */}
                                                    {marketArrivals.slice(0,5).map((m,i)=>(
                                                        <Card key={i} style={{border: '1px solid rgba(0,0,0,0.3)'}}>
                                                            <CardBody className="pt-2 pb-0">
                                                                <h5 align="left">{i+1}. {m.market}</h5>
                                                                <h5 align="left">Arrivals: {parseInt(m.total_arrival)?.toLocaleString()}</h5>
                                                            </CardBody>
                                                        </Card>
                                                    ))}
                                                </Col>
                                                <Col className="d-none d-md-block" md={8}>
                                                    <img src={worldMap} alt="World Map" align="center" height="500" />
                                                </Col>
                                                <Col xs={12} md={2}>
                                                    {/* last 5 market arrivals */}
                                                    {marketArrivals.slice(5,10).map((m,i)=>(
                                                        <Card key={i} style={{border: '1px solid rgba(0,0,0,0.3)'}}>
                                                            <CardBody className="pt-2 pb-0">
                                                                <h5 align="left">{i+6}. {m.market}</h5>
                                                                <h5 align="left">Arrivals: {parseInt(m.total_arrival)?.toLocaleString()}</h5>
                                                            </CardBody>
                                                        </Card>
                                                    ))}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Card style={{backgroundColor: '#7dabf5'}}>
                                                <CardBody>
                                                    <h3 align="center" style={{color: 'white'}}>Total Arrivals - {selectedMarketYear.label}</h3>
                                                    <h1 align="center" style={{color: 'white'}}>{totalArrivals?.toLocaleString()}</h1>
                                                </CardBody>
                                            </Card>
                                            <Card style={{backgroundColor: '#7dabf5'}}>
                                                <CardBody>
                                                    <h4 align="center" style={{color: 'white'}}>Total Travel Receipts - {selectedMarketYear.label}</h4>
                                                    <h1 align="center" style={{color: 'white'}}>{totalReceipts ? `$${totalReceipts?.toLocaleString()}` : "-"}</h1>
                                                </CardBody>
                                            </Card>
                                            <Card style={{backgroundColor: '#7dabf5'}}>
                                                <CardBody>
                                                    <h4 align="center" style={{color: 'white'}}>Travel Receipts per Tourist - {selectedMarketYear.label}</h4>
                                                    <h1 align="center" style={{color: 'white'}}>{totalReceiptsPerTourist ? `$${totalReceiptsPerTourist?.toLocaleString()}` : "-"}</h1>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Loader loading={chartLoading} size="top" />
                                    <h3 align="center">Tourist Arrivals</h3>
                                    <Select options={years} value={selectedChartYears} classNamePrefix="select2-selection" isMulti={true} isClearable={false} onChange={(e)=>setSelectedChartYears(e.map((val)=>({label: val?.label, value: val?.value})))} />
                                    <ReactApexChart options={options} series={seriesData} type="bar" height={350} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default Home;

