
import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';

//i18n
// import { withNamespaces } from 'react-i18next/withNamespaces';
import { changePasswordRequest, logoutRequest } from '../../../api/controller/AuthController';
import { DynamicModal } from '../../../custom/components/DynamicModal';

const formModes = {
    create: [ { name: 'Default Create', action: changePasswordRequest } ],
}

const dataStructure = [
    { 
        label: "Current Password",
        type: "password",
        order: { form: 1 },
        create: [{ enabled: true, size: 12 }],
    },
    { 
        label: "Password",
        type: "password",
        order: { form: 2 },
        create: [{ enabled: true, size: 12 }],
    },
    { 
        label: "Confirm Password",
        name: "Password_confirmation",
        type: "password",
        order: { form: 3 },
        create: [{ enabled: true, size: 12 }],
    },
]

const ProfileMenu = ({user, history}) => {

    const [menu, setMenu] = useState(false)
    const [modalConfig, setModalConfig] = useState({visible: false, size: "sm", wizard: false, header: 'Change Password', absoluteHeader:true, type: null, typeKey: null, data: {}}) 

    const toggle = () => {
        setMenu(!menu)
    }

    const logout = async() => {
        const response = await logoutRequest()
        if(response){
            localStorage.removeItem('authToken')
            window.location.replace("/");
        }
    }

    const toggleModal = (row={}, mode=null, modeKey=0) => {
        setModalConfig({ ...modalConfig, visible: !modalConfig.visible, type: typeof(mode) === 'string' ? mode : null, typeKey: modeKey, data: row })
    }
        
    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => toggle()} className="d-inline-block" >
                <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                    <img className="rounded-circle header-profile-user" src={user.avatar} alt="Header Avatar" />
                    <span className="d-none d-xl-inline-block ml-2 mr-1"  style={{'color':'#050A30'}} >{user.name}</span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-left-user">
                    <DropdownItem onClick={(e) => {e.preventDefault();toggleModal({}, "Create")}}>
                        <i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>{'Change Password'}
                    </DropdownItem>
                    <div className="dropdown-divider"></div>

                    <DropdownItem tag="a" onClick={()=>logout()} className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>{'Logout'}</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <DynamicModal config={modalConfig} toggleModal={toggleModal} submitModes={formModes} ds={dataStructure} />
        </React.Fragment>
    );
}

export default withRouter((ProfileMenu));
