import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getNewsletterListRequest } from "../../../api/controller/ArticleController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const articleListState = atom({
    key: 'articleListState',
    default: [],
})
export const articleNewsletterState = atom({
	key: 'articleNewsletterState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = []

export const filterArticleNewsletterState = atom({
    key: 'filterArticleNewsletterState',
    default: '',
})

export const articleFilterState = selector({
    key: 'articleFilterState',
    get: ({ get }) => (
        [
            {
                label: 'Newsletter',
                name: 'Newsletter',
                size: 6,
                offset: 6,
                required: true,
                type: 'select',
                value: get(filterArticleNewsletterState),
                list: get(articleNewsletterState)
            }
        ]
    ),
    set: ({ set }, e) => {
        if(e.filterLabel === 'Newsletter'){
            set(filterArticleNewsletterState, e)
        }
    }
})

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const articleDataStructureState = selector({
    key: 'articleDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Newsletter',
                type: 'select',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(articleNewsletterState),
				getRequest: getNewsletterListRequest,
            },
			{ 
                label: 'Name',
                type: 'text',
                order: { table: 2, form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Description',
                type: 'textarea',
                order: { table: 3, form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 } , attributes: { maxLength: '225', rows: '3' } }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Cover',
                type: 'file',
                order: { form: 4 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Content',
                type: 'wysiwyg',
                order: { form: 5 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
