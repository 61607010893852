
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import { Loader } from "../../../custom/components/Loader";
import { getArrivalsByMarketRequest } from "../../../api/controller/ResourceController";

const ArrivalsByMarket = () => {

    const [marketData, setMarketData] = useState([])
    const [years, setYears] = useState([])
    const [markets, setMarkets] = useState([])

    const [selectedYears, setSelectedYears] = useState([])
    const [selectedMarket, setSelectedMarket] = useState({ label: "Austria", value: "Austria" })
    const [seriesData, setSeriesData] = useState([])
    const [fullSeriesData, setFullSeriesData] = useState([])
    const [categories] = useState(["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function getData(){
            setLoading(true)
            const response = await getArrivalsByMarketRequest({Market: selectedMarket.value});
            if(response && response.marketData) setMarketData(response.marketData);
            if(response && response.years) setYears(response.years);
            if(response && response.markets) setMarkets(response.markets);
            let data = []
            response.years.forEach((y)=>{
                let tempData = Array(12).fill(0)
                response.marketData[y].forEach((d)=>{
                    tempData[d.month-1] = d.total_arrival
                });
                data.push({name: y, data: tempData})
            });
            setFullSeriesData(data)
            setLoading(false)
        }
        getData();
    }, [selectedMarket])

    useEffect(() => {
        if(selectedYears.length === 0){
            setSelectedYears(years.map(y=>({label: y, value: y})));
        }
    }, [years, selectedYears.length])

    useEffect(() => {
        if(Object.keys(marketData).length > 0 && selectedYears.length > 0 && selectedMarket.value){
            let data = []
            selectedYears?.forEach((y)=>{
                let tempData = Array(12).fill(0)
                marketData[y.value].forEach((d)=>{
                    tempData[d.month-1] = d.total_arrival
                });
                data.push({name: y.label, data: tempData})
            })
            setSeriesData(data)
        } else {
            setSeriesData([])
        }
    }, [selectedYears, marketData, selectedMarket.value])


    const options = {
        chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
        colors: ["#34c38f", "#556ee6", "#f46a6a", "#f1c40f", "#3498db", "#1abc9c", "#9b59b6", "#e74c3c", "#2ecc71", "#34495e"],
        dataLabels: { enabled: !1 },
        stroke: { width: [3, 4, 3], curve: "straight", dashArray: [0, 8, 5] },
        markers: { size: 0, hover: { sizeOffset: 6 } },
        xaxis: {
          categories: categories,
        },
        grid: { borderColor: "#f1f1f1" },
    }
    // const options = {
    //     chart: {
    //         toolbar: { show: false, },
    //     },
    //     plotOptions: {
    //         bar: { horizontal: false, columnWidth: "45%", endingShape: "rounded" },
    //     },
    //     dataLabels: { enabled: false },
    //     stroke: { show: true, width: 2, colors: ["transparent"] },
    //     colors: ["#34c38f", "#556ee6", "#f46a6a", "#f1c40f", "#3498db", "#1abc9c", "#9b59b6", "#e74c3c", "#2ecc71", "#34495e"],
    //     xaxis: {
    //         categories: categories,
    //     },
    //     yaxis: { title: { text: "Arrival Count", }, },
    //     grid: { borderColor: "#f1f1f1", },
    //     fill: { opacity: 1, },
    //     tooltip: {
    //         y: {
    //         formatter: function (val) {
    //             return val + " Movement";
    //         },
    //         },
    //     },
    // };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Loader loading={loading} size="top" />
                                    <h3 align="center">Tourist Arrivals By Markets - {selectedMarket.label}</h3>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Select options={markets.map(m=>({label: m, value: m}))} value={selectedMarket} classNamePrefix="select2-selection" isClearable={false} onChange={(e)=>setSelectedMarket({label: e?.label, value: e?.value})} />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Select options={years.map(y=>({label: y, value: y}))} value={selectedYears} classNamePrefix="select2-selection" isMulti={true} isClearable={false} onChange={(e)=>setSelectedYears(e.map((val)=>({label: val?.label, value: val?.value})))} />
                                        </Col>
                                    </Row>
                                    <ReactApexChart options={options} series={seriesData} type="line" height={350} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Loader loading={loading} size="top" />
                                        <div className="table-responsive">
                                            <Table className="custom-table table mb-0">
                                                <thead>
                                                    <tr className="ct-header">
                                                        <th>#</th>
                                                        <th key="operator">Year</th>
                                                        {(categories.map(m=>(<th key={m}>{m}</th>)))}
                                                        <th key="total">TOTAL</th>
                                                    </tr>
                                                </thead>
                                            <tbody>
                                                {Object.keys(marketData).map((o, i)=>(
                                                    <tr key={i}>
                                                        <th className="ct-left-header" scope="row">{i+1}</th>
                                                        <th className="ct-left-header" scope="row">{o}</th>
                                                        {fullSeriesData[i]?.data.map((d, j)=>(<td key={j}>{d || "-"}</td>))}
                                                        <td>{fullSeriesData[i]?.data.reduce((a, b) => parseInt(a) + parseInt(b), 0)}</td>
                                                    </tr>   
                                                ))}
                                            </tbody>
                                            </Table>
                                        </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default ArrivalsByMarket;

