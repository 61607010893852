import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, mailRequest } from "../../../api/controller/ManageNewsletterController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const newsletterListState = atom({
    key: 'newsletterListState',
    default: [],
})

export const newsletterContactGroupState = atom({
    key: 'newsletterContactGroupState',
    default: [],
})


export const modalConfigDefault = { size: "md", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest }, { name: 'Mail Newsletter', action: mailRequest, closeOnSubmit: true } ],
    view: [ { name: 'Default View' } ],
}

export const newsletterDataStructureState = selector({
    key: 'newsletterDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Name',
                type: 'text',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Description',
                type: 'textarea',
                order: { table: 2, form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 } , attributes: { maxLength: '225', rows: '3' } }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Cover',
                type: 'file',
                order: { form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Date',
                type: 'datepicker',
                order: { table: 3, form: 4 }, 
				table: { dateFormat: 'MMM Do, YYYY', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: true, size: { xs: 12, md: 12 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], update: ['same_as:create.0', { enabled: false }], view: ['same_as:create.0'], 
            },
            {
                label : 'Contact Groups',
                subFields: { label: 'display_name', value: 'id' },
                type: 'multiselect',
                order: { form: 5 },
                create: [{ enabled: false }], update: ['same_as:create.0', { enabled: true, size: { xs: 12, md: 12 } }], view: ['same_as:create.0'], 
                list: get(newsletterContactGroupState)
            }

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
