
import { Card, CardBody, Col, Container, Row, Label, Table } from "reactstrap";
import React, { useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import { Loader } from "../../../custom/components/Loader";
import { getDailyMovementComparisonRequest, getOperatorComparisonRequest } from "../../../api/controller/ResourceController";
import moment from "moment";
import { handleResponse } from "../../../custom/helpers/HandleResponse";

const AirTrafficMovements = () => {

    const [atmData, setATMData] = useState([])
    const [metrics] = useState([{label: "By Day", value: "byDay"}, {label: "By Operator", value: "byOperator"}])
    const [years] = useState([{label: "2023", value: "2023"}, {label: "2022", value: "2022"}, {label: "2021", value: "2021"}, {label: "2020", value: "2020"}, {label: "2019", value: "2019"}, ])
    const [months] = useState([{label: "January", value: "1"}, {label: "February", value: "2"}, {label: "March", value: "3"}, {label: "April", value: "4"}, {label: "May", value: "5"}, {label: "June", value: "6"}, {label: "July", value: "7"}, {label: "August", value: "8"}, {label: "September", value: "9"}, {label: "October", value: "10"}, {label: "November", value: "11"}, {label: "December", value: "12"}])

    // current year
    const currentYear = new Date().getFullYear();
    const previousMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1);
    const prevMonth = previousMonth.getMonth() + 1;
    const fullMonthName = previousMonth.toLocaleString('default', { month: 'long' });
    const [selectedMetric, setSelectedMetric] = useState({label: "By Day", value: "byDay"})
    const [selectedYear, setSelectedYear] = useState({label: currentYear, value: currentYear })
    const [selectedMonth, setSelectedMonth] = useState({ label: fullMonthName, value: prevMonth })
    const [selectedComparisonYear, setSelectedComparisonYear] = useState({label: currentYear-1, value: currentYear-1 })
    const [seriesData, setSeriesData] = useState([])
    const [categories, setCategories] = useState([])

    const [loading, setLoading] = useState(true)

    const mappings = useMemo(() => ({
        byDay: { fn: getDailyMovementComparisonRequest, header: "Daily ATMs and Arrival Pax", yHeader: "", yFormatter: (val) => val.toLocaleString() },
        byOperator: { fn: getOperatorComparisonRequest, header: "ATMs and Arrival Pax by Operator", yHeader: "", yFormatter: (val) => val.toLocaleString() },
    }), [])

    useEffect(() => {
        async function getData(){
            setLoading(true)
            const response = await mappings[selectedMetric.value].fn({Year: selectedYear.value, Month: selectedMonth.value, ComparisonYear: selectedComparisonYear.value });
            if(response && response.data){
                setATMData(response.data)
            } else {
                setATMData([])
                handleResponse(response)
            }
            setLoading(false)
        }
        getData();
    }, [selectedMetric, selectedYear, selectedMonth, selectedComparisonYear, mappings])

    useEffect(() => {
        if(atmData?.length > 0 && selectedYear.value && selectedMonth.value && selectedComparisonYear.value){
            setSeriesData([{ name: selectedYear.label, data: atmData.map(d=>d.arrival) },{ name: selectedComparisonYear.label, data: atmData.map(d=>d.comparison_arrival) }])
            if(selectedMetric.value === "byDay"){
                setCategories(atmData.map(d=>moment(d.date).format("DD-MMM")))
            } else {
                setCategories(atmData.map(d=>d.operator))
            }
        } else if(!loading) {
            setSeriesData([])
        }
    }, [selectedYear, selectedMonth.value, selectedComparisonYear, atmData, selectedMetric.value, loading])


    const options = {
        chart: {
            toolbar: { show: false, },
        },
        plotOptions: {
            bar: { horizontal: false, columnWidth: "45%", endingShape: "rounded" },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#34c38f", "#556ee6", "#f46a6a", "#f1c40f", "#3498db", "#1abc9c", "#9b59b6", "#e74c3c", "#2ecc71", "#34495e"],
        xaxis: {
            categories: categories,
        },
        yaxis: { title: { text: "Arrival Count", }, },
        grid: { borderColor: "#f1f1f1", },
        fill: { opacity: 1, },
        tooltip: {
            y: {
            formatter: function (val) {
                return val.toLocaleString();
            },
            },
        },
    };
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Loader loading={loading} size="top" />
                                    <h3 align="center">{mappings[selectedMetric.value].header}</h3>
                                    <Row>
                                        <Col xs={12} md={3}>
                                            <Label>Metric</Label>
                                            <Select options={metrics} value={selectedMetric} classNamePrefix="select2-selection" isClearable={false} onChange={(e)=>setSelectedMetric({label: e?.label, value: e?.value})} />
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Label>Year</Label>
                                            <Select options={years} value={selectedYear} classNamePrefix="select2-selection" isClearable={false} onChange={(e)=>setSelectedYear({label: e?.label, value: e?.value})} />
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Label>Month</Label>
                                            <Select options={months} value={selectedMonth} classNamePrefix="select2-selection" isClearable={false} onChange={(e)=>setSelectedMonth({label: e?.label, value: e?.value})} />
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <Label>Comparison Year</Label>
                                            <Select options={years} value={selectedComparisonYear} classNamePrefix="select2-selection" isClearable={false} onChange={(e)=>setSelectedComparisonYear({label: e?.label, value: e?.value})} />
                                        </Col>
                                    </Row>
                                    <ReactApexChart options={options} series={seriesData} type="bar" height={620} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Loader loading={loading} size="top" />
                                        <div className="table-responsive">
                                            <Table className="custom-table table mb-0">
                                                <thead>
                                                    <tr className="ct-header" align="center">
                                                        <th rowSpan="2" key="day" style={{verticalAlign:'middle'}}>Day</th>
                                                        <th colSpan="3">Total Arrivals</th>
                                                        <th colSpan="3">Total Movements</th>
                                                    </tr>
                                                    <tr className="ct-header" align="center">
                                                        <th>{selectedComparisonYear.label}</th>
                                                        <th>{selectedYear.label}</th>
                                                        <th>Variance</th>
                                                        <th>{selectedComparisonYear.label}</th>
                                                        <th>{selectedYear.label}</th>
                                                        <th>Variance</th>
                                                    </tr>
                                                </thead>
                                            <tbody>
                                                {Object.keys(atmData).map((_,i)=>(
                                                    <tr key={i} align="center">
                                                        <th className="ct-left-header" scope="row">{categories[i]}</th>
                                                        <td>{atmData[i]?.comparison_arrival?.toLocaleString()}</td>
                                                        <td>{atmData[i]?.arrival.toLocaleString()}</td>
                                                        <td style={{color: atmData[i].arrival_roc < 0 ? "#ad1328" : "#29a616"}}>{atmData[i].arrival_roc}% <span className={"mdi mdi-trending-"+(atmData[i].arrival_roc < 0 ? "down" : (atmData[i].arrival_roc === 0 ? "neutral" : "up"))}></span></td>
                                                        <td>{atmData[i]?.comparison_movement?.toLocaleString()}</td>
                                                        <td>{atmData[i]?.movement?.toLocaleString()}</td>
                                                        <td style={{color: atmData[i].movement_roc < 0 ? "#ad1328" : "#29a616"}}>{atmData[i].movement_roc}% <span className={"mdi mdi-trending-"+(atmData[i].movement_roc < 0 ? "down" : (atmData[i].movement_roc === 0 ? "neutral" : "up"))}></span></td>
                                                    </tr>   
                                                ))}
                                                <tr align="center">
                                                    <th className="ct-left-header" scope="row">Total</th>
                                                    <td>{atmData.reduce((a, b) => parseInt(a) + parseInt(b.comparison_arrival), 0).toLocaleString()}</td>
                                                    <td>{atmData.reduce((a, b) => parseInt(a) + parseInt(b.arrival), 0).toLocaleString()}</td>
                                                    <td>{((atmData.reduce((a, b) => parseInt(a) + parseInt(b.arrival), 0) - atmData.reduce((a, b) => parseInt(a) + parseInt(b.comparison_arrival), 0))/atmData.reduce((a, b) => parseInt(a) + parseInt(b.comparison_arrival), 0) * 100).toFixed(0)}%</td>
                                                    <td>{atmData.reduce((a, b) => parseInt(a) + parseInt(b.comparison_movement), 0).toLocaleString()}</td>
                                                    <td>{atmData.reduce((a, b) => parseInt(a) + parseInt(b.movement), 0).toLocaleString()}</td>
                                                    <td>{((atmData.reduce((a, b) => parseInt(a) + parseInt(b.movement), 0) - atmData.reduce((a, b) => parseInt(a) + parseInt(b.comparison_movement), 0))/atmData.reduce((a, b) => parseInt(a) + parseInt(b.comparison_movement), 0) * 100).toFixed(0)}%</td>
                                                </tr>
                                            </tbody>
                                            </Table>
                                        </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default AirTrafficMovements;

