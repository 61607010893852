import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getOperatorListRequest } from "../../../api/controller/OperatorArrivalController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const operatorArrivalListState = atom({
    key: 'operatorArrivalListState',
    default: [],
})
export const operatorArrivalOperatorState = atom({
	key: 'operatorArrivalOperatorState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const operatorArrivalDataStructureState = selector({
    key: 'operatorArrivalDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Date',
                type: 'datepicker',
                order: { table: 1, form: 1 }, 
				table: { editable: true, dateFormat: 'YYYY MMMM', postDateFormat:'YYYY-MM-DD' },
                create: [{ enabled: true, size: { xs: 12, md: 6 } , dateFormat: 'YYYY-MM-DD', options: { altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d' } }], update: [{ enabled: false }], view: ['same_as:update.0'], 
            },
			{ 
                label: 'Operator',
                type: 'select',
                rootValue: true,
                order: { table: 2, form: 2 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: [{ enabled: false }], view: ['same_as:update.0'], 
				list: get(operatorArrivalOperatorState),
				getRequest: getOperatorListRequest,
            },
			{ 
                label: 'Arrival',
                type: 'number',
                order: { table: 3, form: 3 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, default: '0' }], update: [{ enabled: false }], view: ['same_as:update.0'], 
            },
			{ 
                label: 'Charter Arrival',
                type: 'number',
                order: { table: 4, form: 4 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, default: '0' }], update: [{ enabled: false }], view: ['same_as:update.0'], 
            },
			{ 
                label: 'Movement',
                type: 'number',
                order: { table: 5, form: 5 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, default: '0' }], update: [{ enabled: false }], view: ['same_as:update.0'], 
            },
			{ 
                label: 'Charter Movement',
                type: 'number',
                order: { table: 6, form: 6 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, default: '0' }], update: [{ enabled: false }], view: ['same_as:update.0'], 
            },
			{ 
                label: 'Cargo Movement',
                type: 'number',
                order: { table: 7, form: 7 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, default: '0' }], update: [{ enabled: false }], view: ['same_as:update.0'], 
            },
			{ 
                label: 'Air Traffic Movement',
                tableHeader: 'ATM',
                type: 'number',
                order: { table: 8, form: 8 }, 
				table: { editable: true },
                create: [{ enabled: true, size: { xs: 12, md: 6 }, default: '0' }], update: [{ enabled: false }], view: ['same_as:update.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
