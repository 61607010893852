import React, { useState } from "react";
import { useRecoilValue } from 'recoil';
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { themeTypeState } from "../../state/GlobalState";
// Redux
import { Link, withRouter } from "react-router-dom";
// availity-reactstrap-validation
// import { AvField, AvForm } from "availity-reactstrap-validation";
// action
// import images
import logo from "../../assets/images/logo-or.png";
import { registerRequest } from "../../api/controller/AuthController";
import { handleResponse } from "../../custom/helpers/HandleResponse";
import { Loader } from '../../custom/components/Loader';
import videoURL from "../../assets/images/sp_beach.mp4";


const Register = ({history}) => {
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const themeType = useRecoilValue(themeTypeState)
  const [form, setForm] = useState({
    Name: '',
    Email: '',
    MobileNumber: '',
    Address: '',
  })


  // handleValidSubmit
  const handleValidSubmit = async(event) => {
    event.preventDefault()
    setSubmitDisabled(true)
    const response = await registerRequest({ Name: form.Name, Email: form.Email, MobileNumber: form.MobileNumber, Address: form.Address })
    handleResponse(response)
    if(! (response?.Status === "error")){
      setSubmitDisabled(false)
      history.push("/login")
    } else {
      setSubmitDisabled(false)
    }
  }


  return (
    <Container fluid className="p-0" style={{overflow:'hidden'}}>
    <Row className="no-gutters">
        <React.Fragment>
          <Col xl={9} style={{ backgroundColor:'#000'}}>
            <div className="auth-full-bg">
              <div className="w-100">
                <div className="bg-overlay">
                <video loop muted autoPlay style={{height:'100vh'}}>
                          <source src={videoURL} type="video/mp4" />
                          <source src={videoURL} type="video/ogg" />
                          Your browser does not support the video tag.
                      </video>
                      </div>
              </div>
            </div>
          </Col>
        </React.Fragment>
        <Col xl={3} style={{ color:'#000', backdropFilter: 'blur(70px)'}}>
          <div className="auth-full-page-content p-md-5 p-4" >
            <div className="w-100">
              <div className="d-flex flex-column h-100">
                  <div className="my-auto mt-5">
                    <Loader loading={submitDisabled} transparent />
                    <div align="center" className="mb-4 mb-md-5"> 
                      <img src={logo} alt="" height="90" style={{justifyContent:'center', alignItem:'center'}} />
                    </div>
                    
                    <Row>
                      <Col className="col-12">
                        <div className="text-primary pb-2 p-2">
                          <h5 className="text-black">Request for Registration !</h5>
                          <p className="mb-0 text-black">Please submit request to continue.</p>
                        </div>
                      </Col>
                    </Row>
                    <div className="p-2">

                      <Form className="form-horizontal mt-4" onSubmit={handleValidSubmit}>
                        <FormGroup>
                          <Label>Name</Label>
                          <Input name="Name" label="Name" className="form-control" placeholder="Enter your Name" value={form.Name} onChange={(e)=>setForm({...form, Name: e.target.value})} type="text" required />
                        </FormGroup>
                        <FormGroup>
                          <Label>Email</Label>
                          <Input name="Email" label="Email" className="form-control" placeholder="Enter your Email" value={form.Email} onChange={(e)=>setForm({...form, Email: e.target.value})} type="email" required />
                        </FormGroup>
                        {/* <FormGroup>
                          <Label>Mobile Number</Label>
                          <Input name="MobileNumber" label="MobileNumber" className="form-control" placeholder="Enter your Mobile Number" value={form.MobileNumber} onChange={(e)=>setForm({...form, MobileNumber: e.target.value})} type="number" required />
                        </FormGroup>
                        <FormGroup>
                          <Label>Address</Label>
                          <Input name="Address" label="Address" className="form-control" placeholder="Enter your Address" value={form.Address} onChange={(e)=>setForm({...form, Address: e.target.value})} type="text" required />
                        </FormGroup> */}
                        <Row className="form-group">
                          <Col className="text-right mt-3">
                            <button className="btn btn-primary w-100 waves-effect waves-light" disabled={submitDisabled} type="submit">Request Registration</button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                    <div className="mt-5 text-center">
                          <p className={(themeType === 'dark' ? 'text-white' : '')}>Go back to{" "}<Link to="login" className={"font-weight-medium " + (themeType === 'dark' ? 'text-info' : 'text-primary')}>Login</Link>{" "}</p>
                        </div>

                        <div className="mt-4 mt-md-5 text-center">
                          <p className="mb-0">
                              © {new Date().getFullYear()} MATI. {""}<br/> 
                              Made with <i className="mdi mdi-heart text-danger"></i> in Maldives.
                          </p>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
  );
}
export default withRouter(Register);
