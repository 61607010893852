import { handleBlobResponse } from "../../custom/helpers/HandleResponse";
import http from "../core/http";
const group = 'statistics';

export const getMaclDataRequest =  async (data) => {
    return await http.post(`${group}/getMaclData`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getMaclOperatorArrivalsRequest =  async (data) => {
    return await http.post(`${group}/getMaclOperatorArrivals`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getTaxDataRequest =  async (data) => {
    return await http.post(`${group}/getTaxData`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getBedDataRequest =  async (data) => {
    return await http.post(`${group}/getBedData`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getCompilationFixedRequest =  async (data) => {
    return await http.post(`${group}/getCompilationFixed`, data).then(res => res.data).catch(http.defaultCatch);
}


export const getCompilationPerXRequest =  async (data) => {
    return await http.post(`${group}/getCompilationPerX`, data).then(res => res.data).catch(http.defaultCatch);
}

export const downloadCompilationRequest = async (data) => {
    const response = await http({url: `${group}/downloadCompilation`, method: "POST", data: data, responseType: "blob"}).then(res => res.data).catch(http.defaultCatch);
    handleBlobResponse(response, `Statistical-Compilation.xlsx`);
}
