import http from "../core/http";
const group = 'resources';

export const getArrivalsByMarketRequest =  async (data) => {
    return await http.post(`${group}/getArrivalsByMarket`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getDailyMovementComparisonRequest =  async (data) => {
    return await http.post(`${group}/getDailyMovementComparison`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getOperatorComparisonRequest =  async (data) => {
    return await http.post(`${group}/getOperatorComparison`, data).then(res => res.data).catch(http.defaultCatch);
}

export const getTaxComparisonRequest =  async (data) => {
    return await http.post(`${group}/getTaxComparison`, data).then(res => res.data).catch(http.defaultCatch);
}