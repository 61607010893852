import React from "react";

import {withRouter} from "react-router-dom";
//Simple bar
import SimpleBar from "simplebar-react";
//i18n
// import {withNamespaces} from 'react-i18next/withNamespaces';
import { useRecoilValue } from 'recoil';
import { navigationState } from "../../state/NavigationState";
import SidebarContent from "./SidebarContent";


const Sidebar = props => {

    const navigation = useRecoilValue(navigationState);

    return (
        <React.Fragment>
            <div className="vertical-menu" style={{ backgroundColor:'#0A2647', color:'#000', backdropFilter: 'blur(70px)'}}>
                <div data-simplebar className="h-100">
                    {props.type !== "condensed" ? (
                        <SimpleBar style={{ maxHeight: "100%" }}>
                            <SidebarContent navItems={navigation} />
                        </SimpleBar>
                    ) : <SidebarContent navItems={navigation} />}
                </div>

            </div>
        </React.Fragment>
    );
}
export default React.memo(withRouter((Sidebar)));
