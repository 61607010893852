import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from '@dgautalius/react-pdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewPDF = ({ url }) => {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1);
  const [pdfUrl, setPdfUrl] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (offset) => {
    setCurrentPage((prevPage) => prevPage + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  const zoomOut = () => {
    setScale((prevScale) => prevScale - 0.1);
  };

  const zoomIn = () => {
    setScale((prevScale) => prevScale + 0.1);
  };

  const handleDownload = () => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setCurrentPage(1); // Reset to the first page on desktop
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const showPagination = window.innerWidth < 768;

  const fetchPdf = async (url) => {
    const proxyPdfUrl = 'https://corsproxy.io/?' + encodeURIComponent(`${url}`);

    try {
      const response = await fetch(proxyPdfUrl, {
        headers: {
          'Accept': 'application/pdf',
        },
      });
      const blob = await response.blob();
      const pdfUrl = URL.createObjectURL(blob);
      return pdfUrl;
    } catch (error) {
      console.error('Failed to fetch PDF:', error);
      return null;
    }
  };

  const loadPdf = async () => {
    const pdfUrl = await fetchPdf(url);
    if (pdfUrl) {
      setPdfUrl(pdfUrl);
    }
  };

  useEffect(() => {
    loadPdf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {pdfUrl && (
        <Document
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          className="pdf-document"
        >
          {showPagination ? (
            <Page pageNumber={currentPage} width={window.innerWidth} scale={scale} />
          ) : (
            Array.from(new Array(numPages), (_, index) => (
              <Page key={index} pageNumber={index + 1} width={window.innerWidth} scale={scale} />
            ))
          )}
        </Document>
      )}
      {showPagination && (
        <div className="pdf-navigation">
          <button
            className="pdf-navigation-button"
            disabled={currentPage <= 1}
            onClick={previousPage}
          >
            <i className='mdi mdi-chevron-double-left d-block font-size-13'></i>
          </button>
          <p className="pdf-page-counter">
            Page {currentPage} of {numPages}
          </p>
          <button
            className="pdf-navigation-button"
            disabled={currentPage >= numPages}
            onClick={nextPage}
          >
            <i className='mdi mdi-chevron-double-right d-block font-size-13'></i>
          </button>
          <button className="pdf-navigation-button" onClick={zoomOut}>
            -
          </button>
          <button className="pdf-navigation-button" onClick={zoomIn}>
            +
          </button>
          <button className="pdf-download-button" onClick={handleDownload} style={{ marginLeft: '5px' }}>
            <i className='mdi mdi-chevron-double-down d-block font-size-13'></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default ViewPDF;
