
import { Card, CardBody, Col, Container, Row, Label, Table } from "reactstrap";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import { Loader } from "../../../custom/components/Loader";
import { getTaxComparisonRequest } from "../../../api/controller/ResourceController";
import { handleResponse } from "../../../custom/helpers/HandleResponse";

const MiraRevenue = () => {

    const [taxData, setTaxData] = useState([])
    const [metrics] = useState([
        { label: 'Departure Tax', value: 't_departure_tax' },
        { label: 'Green Tax', value: 't_green_tax' },
        { label: 'T-GST', value: 't_tgst' },
        { label: 'Land Rent', value: 't_land_rent' },
        { label: 'Business Permit', value: 't_business_permit' },
        { label: 'Tourism Administration', value: 't_tourism_administration' }
    ])
    const [years] = useState([{label: "2023", value: "2023"}, {label: "2022", value: "2022"}, {label: "2021", value: "2021"}, {label: "2020", value: "2020"}, {label: "2019", value: "2019"}, ])

    // current year
    const currentYear = new Date().getFullYear();
    const [selectedMetric, setSelectedMetric] = useState({label: "Departure Tax", value: "t_departure_tax"})
    const [selectedYear, setSelectedYear] = useState({label: currentYear, value: currentYear })
    const [selectedComparisonYear, setSelectedComparisonYear] = useState({label: currentYear-1, value: currentYear-1 })
    const [seriesData, setSeriesData] = useState([])
    const [tempMonths] = useState(["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"])
    const [categories, setCategories] = useState(tempMonths)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function getData(){
            setLoading(true)
            const response = await getTaxComparisonRequest({Year: selectedYear.value, ComparisonYear: selectedComparisonYear.value });
            if(response && response.data){
                setTaxData(response.data)
            } else {
                setTaxData([])
                handleResponse(response)
            }
            setLoading(false)
        }
        getData();
    }, [selectedYear, selectedComparisonYear])

    useEffect(() => {
        if(taxData?.length > 0 && selectedYear.value && selectedComparisonYear.value){
            setSeriesData([{ name: selectedYear.label, data: taxData.map(d=>d[selectedMetric.value]) },{ name: selectedComparisonYear.label, data: taxData.map(d=>d["c_"+selectedMetric.value]) }])
            setCategories(taxData.map(d=>tempMonths[d.month-1]))
        } else if(!loading) {
            setSeriesData([])
        }
    }, [selectedYear, selectedComparisonYear, selectedMetric, tempMonths, taxData, loading])


    const options = {
        chart: {
            toolbar: { show: false, },
        },
        plotOptions: {
            bar: { horizontal: false, columnWidth: "45%", endingShape: "rounded" },
        },
        dataLabels: { enabled: false },
        stroke: { show: true, width: 2, colors: ["transparent"] },
        colors: ["#34c38f", "#556ee6", "#f46a6a", "#f1c40f", "#3498db", "#1abc9c", "#9b59b6", "#e74c3c", "#2ecc71", "#34495e"],
        xaxis: {
            categories: categories,
        },
        yaxis: { title: { text: "Amount", }, },
        grid: { borderColor: "#f1f1f1", },
        fill: { opacity: 1, },
        tooltip: {
            y: {
            formatter: function (val) {
                return val.toLocaleString();
            },
            },
        },
    };
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Loader loading={loading} size="top" />
                                    <h3 align="center">MIRA Revenue - {selectedMetric.label}</h3>
                                    <Row>
                                        <Col xs={12} md={4}>
                                            <Label>Metric</Label>
                                            <Select options={metrics} value={selectedMetric} classNamePrefix="select2-selection" isClearable={false} onChange={(e)=>setSelectedMetric({label: e?.label, value: e?.value})} />
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Label>Year</Label>
                                            <Select options={years} value={selectedYear} classNamePrefix="select2-selection" isClearable={false} onChange={(e)=>setSelectedYear({label: e?.label, value: e?.value})} />
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Label>Comparison Year</Label>
                                            <Select options={years} value={selectedComparisonYear} classNamePrefix="select2-selection" isClearable={false} onChange={(e)=>setSelectedComparisonYear({label: e?.label, value: e?.value})} />
                                        </Col>
                                    </Row>
                                    <ReactApexChart options={options} series={seriesData} type="bar" height={620} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Loader loading={loading} size="top" />
                                        <div className="table-responsive">
                                            <Table className="custom-table table mb-0">
                                                <thead>
                                                    <tr className="ct-header" align="center">
                                                        <th rowSpan="2" key="day" style={{verticalAlign:'middle'}}>MONTH</th>
                                                        <th colSpan="3">Total {selectedMetric.label}</th>
                                                    </tr>
                                                    <tr className="ct-header" align="center">
                                                        <th>{selectedComparisonYear.label}</th>
                                                        <th>{selectedYear.label}</th>
                                                        <th>Variance</th>
                                                    </tr>
                                                </thead>
                                            <tbody>
                                                {Object.keys(taxData).map((_,i)=>(
                                                    <tr key={i} align="center">
                                                        <th className="ct-left-header" scope="row">{categories[i]}</th>
                                                        <td>{Number(taxData[i]["c_"+selectedMetric.value]).toLocaleString()}</td>
                                                        <td>{Number(taxData[i][selectedMetric.value]).toLocaleString()}</td>
                                                        <td style={{color: taxData[i]["roc_"+selectedMetric.value] < 0 ? "#ad1328" : "#29a616"}}>{taxData[i]["roc_"+selectedMetric.value].toFixed(0)}% <span className={"mdi mdi-trending-"+(taxData[i]["roc_"+selectedMetric.value] < 0 ? "down" : (taxData[i]["roc_"+selectedMetric.value] === 0 ? "neutral" : "up"))}></span></td>
                                                    </tr>   
                                                ))}
                                                <tr align="center">
                                                    <th className="ct-left-header" scope="row">Total</th>
                                                    <td>{taxData.reduce((a, b) => parseInt(a) + parseInt(b["c_"+selectedMetric.value]), 0).toLocaleString()}</td>
                                                    <td>{taxData.reduce((a, b) => parseInt(a) + parseInt(b[selectedMetric.value]), 0).toLocaleString()}</td>
                                                    <td>{((taxData.reduce((a, b) => parseInt(a) + parseInt(b[selectedMetric.value]), 0) - taxData.reduce((a, b) => parseInt(a) + parseInt(b["c_"+selectedMetric.value]), 0))/taxData.reduce((a, b) => parseInt(a) + parseInt(b["c_"+selectedMetric.value]), 0) * 100).toFixed(0)}%</td>
                                                </tr>
                                            </tbody>
                                            </Table>
                                        </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
        
    )
    
}

export default MiraRevenue;

