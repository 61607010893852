import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
// Redux
import { Link, useLocation, withRouter } from "react-router-dom";
// availity-reactstrap-validation
// import { AvField, AvForm } from "availity-reactstrap-validation";
// action
// import images
import logo from "../../assets/images/logo-or.png";
import { validatePasswordReset, initPasswordReset } from "../../api/controller/AuthController";
import { handleResponse } from "../../custom/helpers/HandleResponse";
import { preloaderState, themeTypeState } from "../../state/GlobalState";
import notify from "../../custom/helpers/Notify";
import { passwordResetHashState } from "../../api/state/AuthState";
import { Loader } from '../../custom/components/Loader';
import videoURL from "../../assets/images/sp_beach.mp4";


const ForgotPassword = ({history}) => {
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [preloader, setPreloader] = useRecoilState(preloaderState)
  const setPasswordResetHash = useSetRecoilState(passwordResetHashState)
  const [form, setForm] = useState({ email: '' })
  const themeType = useRecoilValue(themeTypeState)

  const location = useLocation()

  useEffect(() => {
    setPreloader(true)
    if(location.search){
      var params = JSON.parse('{"' + decodeURI(location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
      if(params.ref){
        async function passwordResetFn(params){
          const response = await validatePasswordReset({ ref: params.ref })
          if(response?.Status === "success" && response?.Ref){
            setPasswordResetHash(response.Ref)
            setPreloader(false)
            history.push("/change-password")
          } else {
            setPreloader(false)
            notify({ status: 'error', message: "Invalid Password Reset Link. Please try again later!" });
            history.push("/login")
          }
        }
        passwordResetFn(params)
      } else {
        setPreloader(false)
        history.push("/login")
      }
    } else {
      setPreloader(false)
    }
  }, [history, location.search, setPasswordResetHash, setPreloader]);

  // handleValidSubmit
  const handleValidSubmit = async(event) => {
    event.preventDefault()
    setSubmitDisabled(true)
    const response = await initPasswordReset({ Email: form.email })
    setForm({ email: '' })
    handleResponse(response)
    setSubmitDisabled(false)
  }

  



  return (
    preloader ? (
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>
    ) : (
      <Container fluid className="p-0" style={{overflow:'hidden'}}>
        <Row className="no-gutters">
            <React.Fragment>
              <Col xl={9} style={{ backgroundColor:'#000'}}>
                <div className="auth-full-bg">
                  <div className="w-100">
                    <div className="bg-overlay">
                    <video loop muted autoPlay style={{height:'100vh'}}>
                              <source src={videoURL} type="video/mp4" />
                              <source src={videoURL} type="video/ogg" />
                              Your browser does not support the video tag.
                          </video>
                          </div>
                  </div>
                </div>
              </Col>
            </React.Fragment>
            <Col xl={3} style={{ color:'#000', backdropFilter: 'blur(70px)'}}>
              <div className="auth-full-page-content p-md-5 p-4" >
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                      <div className="my-auto mt-5">
                        <Loader loading={submitDisabled} transparent />
                        <div align="center" className="mb-4 mb-md-5"> 
                          <img src={logo} alt="" height="90" style={{justifyContent:'center', alignItem:'center'}} />
                        </div>
                        
                        <Row>
                          <Col className="col-12">
                            <div className="text-primary p-2">
                              <h5 className="text-black">Forgotten password ?</h5>
                              <p className="text-black">Enter your email to continue.</p>
                            </div>
                          </Col>
                        </Row>
                        <div className="p-2">
                          <Form className="form-horizontal mt-4" onSubmit={handleValidSubmit}>
                            <FormGroup>
                              <Label>Email</Label>
                              <Input name="email" label="Email" className="form-control" placeholder="Enter email" value={form.email} onChange={(e)=>setForm({...form, email: e.target.value})} type="email" required />
                            </FormGroup>
                            <Row className="form-group">
                              <Col className="text-right">
                                <button className="btn btn-primary w-100 waves-effect waves-light" disabled={submitDisabled} type="submit">Reset</button>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                        <div className="mt-5 text-center">
                          <p className={(themeType === 'dark' ? 'text-white' : '')}>Go back to{" "}<Link to="login" className={"font-weight-medium " + (themeType === 'dark' ? 'text-info' : 'text-primary')}>Login</Link>{" "}</p>
                        </div>

                        <div className="mt-4 mt-md-5 text-center">
                          <p className="mb-0">
                              © {new Date().getFullYear()} MATI. {""}<br/> 
                              Made with <i className="mdi mdi-heart text-danger"></i> in Maldives.
                          </p>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    )
  );
}
export default withRouter(ForgotPassword);
