
import { Button, Card, CardBody, Col, Collapse, Container, Nav, NavItem, NavLink, Navbar, NavbarToggler, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import { getNewsletterRequest } from "../../../api/controller/NewsletterController";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { handleResponse } from "../../../custom/helpers/HandleResponse";
import { Loader } from "../../../custom/components/Loader";

const Newsletter = ({nID=null, setActiveNewsletter=false}) => {

    const params = useParams()

    const [id, setID] = useState(null)
    const [articleID, setArticleID] = useState(null)
    const [data, setData] = useState([])
    const [article, setArticle] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(params.newsletter){
            setID(params.newsletter.split(":")[0])
            if(params.newsletter.split(":").length > 1){
                setArticleID(parseInt(params.newsletter.split(":")[1]))
            }
        } else if(nID){
            setID(nID)
        }
    }, [nID, params.newsletter])

    useEffect(() => {
        async function getData(){
            setLoading(true)
            const response = await getNewsletterRequest({Newsletter: id});
            if(response && response.data){
                setData(response.data);
            } else {
                handleResponse(response)
            }
            setLoading(false)

        }
        if(id){
            getData()
        }
    }, [id])

    useEffect(() => {
        if(articleID && data.articles?.length > 0){
            const article = data.articles.find(a=>a.id === articleID)
            setArticle(article)
        }
    }, [data, articleID])

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(false)
    }, [article])

    return (
        <React.Fragment>
            <Loader loading={loading} />
            <Container fluid={nID ? true : false} className={article ? "" : "pt-5"}>
                {
                    article ? (
                        <>
                            <Navbar color="light" light expand="md" className="mb-3">
                                <NavbarToggler onClick={()=>setIsOpen(!isOpen)} />
                                <Collapse isOpen={isOpen} navbar>
                                    <Nav className="ml-auto" navbar>
                                        <NavItem>
                                            <NavLink onClick={()=>setArticle(null)}><b><i className="mdi mdi-chevron-left"/> Back to Newsletter</b></NavLink>
                                        </NavItem>
                                        {data.articles.map((a,i)=>(
                                            <NavItem key={i}>
                                                <NavLink onClick={()=>setArticle(a)}>{a.name}</NavLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                </Collapse>
                            </Navbar>
                            <Row>
                                <Col xs={12} align="center">
                                    <h3>{article.name}</h3>
                                </Col>
                                <Col xs={12} align="center">
                                    <img style={{maxHeight:'400px', maxWidth:'100%'}} src={process.env.REACT_APP_API_PUBLIC_URL + article.cover} alt={article.name} />
                                </Col>
                                <Col xs={12} align="center" className="pb-5">
                                    <div dangerouslySetInnerHTML={{__html: article.content}} />
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            {nID ? (
                                <Button onClick={()=>setActiveNewsletter(null)} className="btn btn-secodnary"><i className="mdi mdi-chevron-left"/> Back</Button>
                            ) : null}
                            <Row>
                                <Col xs={12} align="center">
                                    <h3>{data.name}</h3>
                                </Col>
                                <Col xs={12} align="center">
                                    <img style={{maxHeight:'400px', maxWidth:'100%'}} src={process.env.REACT_APP_API_PUBLIC_URL + data.cover} alt={data.name} />
                                </Col>
                                <Col className="p-4" xs={12} align="center">
                                    <p>{data.description}</p>
                                </Col>
                            </Row>
                            <Row>
                                {data.articles?.map((a,i)=>(
                                    <Col xs={12} sm={6} md={3} key={i}>
                                        <Card>
                                            <CardBody align="center">
                                                <h3>{a.name}</h3>
                                                <img width="100%" src={process.env.REACT_APP_API_PUBLIC_URL + a.cover} alt={a.name} />
                                                <p>{a.description}</p>
                                                <Button className="btn btn-info" onClick={()=>setArticle(a)}>Read More</Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </>
                    )
                }
                
            </Container>
        </React.Fragment>
        
    )
    
}

export default Newsletter;

