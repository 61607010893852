import { atom, selector } from "recoil";
import { createDataRequest, updateDataRequest, getContactGroupListRequest } from "../../../api/controller/EmailController";
import { pageState } from "../../../state/GlobalState";
import { permissionState } from "../../../api/state/AuthState";

export const emailListState = atom({
    key: 'emailListState',
    default: [],
})
export const emailContactGroupState = atom({
	key: 'emailContactGroupState',
	default:{
		data:[],
		ovf:false,
	}
})

export const modalConfigDefault = { size: "lg", wizard: false }

export const filtersDefault = []

export const formModes = {
    create: [ { name: 'Default Create', action: createDataRequest } ], 
    update: [ { name: 'Default Update', action: updateDataRequest } ],
    view: [ { name: 'Default View' } ],
}

export const emailDataStructureState = selector({
    key: 'emailDataStructureState',
    get: ({ get }) => (
        [

            { 
                label: 'Subject',
                type: 'text',
                order: { table: 1, form: 1 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Contact Groups',
                type: 'multiselect',
                subFields: { label: 'display_name', value: 'id' },
                order: { form: 2 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
				list: get(emailContactGroupState),
				getRequest: getContactGroupListRequest,
            },
			{ 
                label: 'Body',
                type: 'wysiwyg',
                order: { form: 3 }, 
                create: [{ enabled: true, size: { xs: 12, md: 12 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },
			{ 
                label: 'Mail Attachments',
                type: 'repeater',
                order: { form: 4 },
                create: [{ enabled: true, size: { xs: 12, md: 12 }, deleteBtnSize: 2, repeatLabel: false }], update: ['same_as:create.0'], view: ['same_as:create.0'],
                children: [
                    { 
                		label: 'Name',
                		type: 'text',
                		order: { form: 1 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					{ 
                		label: 'File',
                		type: 'file',
                		order: { form: 2 }, 
                		create: [{ enabled: true, size: { xs: 12, md: 6 }  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            		},
					
                ]
            },
			{ 
                label: 'Sent On',
                field: 'created_at',
                type: 'datepicker',
                order: { table: 2 }, 
                table: { dateFormat: "MMMM Do YYYY, hh:mm" },
                create: [{ enabled: false  }], update: ['same_as:create.0'], view: ['same_as:create.0'], 
            },

        ].filter((v)=>{
            const page = get(pageState)
            const permissions = get(permissionState)
            if(! v.permission){
                return true
            }
            let absolute = false;
            if(v.permission.substr(0,1) === ":"){
                absolute = true;
                v.permission = v.permission.substr(1)
            }
            v.permission = absolute ? v.permission : v.permission + "-" + page
            return permissions?.includes(v.permission)
        })
    )
})
