import http from "../core/http";
const group = 'public/newsletter';

export const getNewsletterListRequest =  async (data) => {
    return await http.post(`${group}/getAllNewsletters`, data).then(res => res.data).catch(http.defaultCatch);
}


export const getNewsletterRequest =  async (data) => {
    return await http.post(`${group}/getNewsletter`, data).then(res => res.data).catch(http.defaultCatch);
}

export const unsubscribeRequest =  async (data) => {
    return await http.post(`${group}/unsubscribe`, data).then(res => res.data).catch(http.defaultCatch);
}