import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { themeTypeState } from "../../state/GlobalState";
// Redux
import { Link, withRouter } from "react-router-dom";
// availity-reactstrap-validation
// import { AvField, AvForm } from "availity-reactstrap-validation";
// action
// import images
import logo from "../../assets/images/logo-or.png";
import { completePasswordReset } from "../../api/controller/AuthController";
import { handleResponse } from "../../custom/helpers/HandleResponse";
import { passwordResetHashState } from "../../api/state/AuthState";
import { Loader } from '../../custom/components/Loader';
import videoURL from "../../assets/images/sp_beach.mp4";


const ChangePassword = ({history}) => {
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [passwordResetHash, setPasswordResetHash] = useRecoilState(passwordResetHashState)
  const themeType = useRecoilValue(themeTypeState)
  const [form, setForm] = useState({
    password: '',
    confirmPassword: '',
  })

  useEffect(() => {
    if(!passwordResetHash){
      history.push("/")
    }
  }, [passwordResetHash, history]);

  // handleValidSubmit
  const handleValidSubmit = async(event) => {
    event.preventDefault()
    setSubmitDisabled(true)
    const response = await completePasswordReset({ ref: passwordResetHash, Password: form.password, Password_confirmation: form.confirmPassword })
    handleResponse(response)
    if(! (response?.Status === "error")){
      setPasswordResetHash(null)
      setSubmitDisabled(false)
      history.push("/login")
    } else {
      setSubmitDisabled(false)
    }
  }


  return (
    <Container fluid className="p-0" style={{overflow:'hidden'}}>
    <Row className="no-gutters">
        <React.Fragment>
          <Col xl={9} style={{ backgroundColor:'#000'}}>
            <div className="auth-full-bg">
              <div className="w-100">
                <div className="bg-overlay">
                <video loop muted autoPlay style={{height:'100vh'}}>
                          <source src={videoURL} type="video/mp4" />
                          <source src={videoURL} type="video/ogg" />
                          Your browser does not support the video tag.
                      </video>
                      </div>
              </div>
            </div>
          </Col>
        </React.Fragment>
        <Col xl={3} style={{ color:'#000', backdropFilter: 'blur(70px)'}}>
          <div className="auth-full-page-content p-md-5 p-4" >
            <div className="w-100">
              <div className="d-flex flex-column h-100">
                  <div className="my-auto mt-5">
                    <Loader loading={submitDisabled} transparent />
                    <div align="center" className="mb-4 mb-md-5"> 
                      <img src={logo} alt="" height="90" style={{justifyContent:'center', alignItem:'center'}} />
                    </div>
                    
                    <Row>
                      <Col className="col-12">
                        <div className="text-primary p-4">
                          <h5 className={(themeType === 'dark' ? 'text-white' : 'text-primary')}>Reset your password !</h5>
                          <p>Change your password to continue.</p>
                        </div>
                      </Col>
                    </Row>
                    <div className="p-2">

                      <Form className="form-horizontal mt-4" onSubmit={handleValidSubmit}>
                        <FormGroup>
                          <Label>Password</Label>
                          <Input name="Password" label="New Password" className="form-control" placeholder="Enter New Password" value={form.password} onChange={(e)=>setForm({...form, password: e.target.value})} type="password" required />
                        </FormGroup>
                        <FormGroup>
                          <Label>Password Confirmation</Label>
                          <Input name="Password_confirmation" label="Confirm Password" className="form-control" value={form.confirmPassword} onChange={(e)=>setForm({...form, confirmPassword: e.target.value})} placeholder="Confirm your Password" type="password" required />
                        </FormGroup>
                        <Row className="form-group">
                          <Col className="text-right">
                            <button className="btn btn-primary w-100 waves-effect waves-light" disabled={submitDisabled} type="submit">Reset</button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                    <div className="mt-5 text-center">
                          <p className={(themeType === 'dark' ? 'text-white' : '')}>Go back to{" "}<Link to="login" className={"font-weight-medium " + (themeType === 'dark' ? 'text-info' : 'text-primary')}>Login</Link>{" "}</p>
                        </div>

                        <div className="mt-4 mt-md-5 text-center">
                          <p className="mb-0">
                              © {new Date().getFullYear()} MATI. {""}<br/> 
                              Made with <i className="mdi mdi-heart text-danger"></i> in Maldives.
                          </p>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
  );
}
export default withRouter(ChangePassword);
