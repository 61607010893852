
import { handleBlobResponse } from "../../custom/helpers/HandleResponse";
import http from "../core/http";

const group = 'general';

export const uploadEditorImageRequest =  async (data) => {
    return await http.post(`${group}/uploadEditorImage`, data).then(res => res.data).catch(error => ({"status":0, "message":error.message}));
}

export const downloadDataRequest = async (data) => {
    const response = await http({url: `${group}/downloadData`, method: "POST", data: data, responseType: "blob"}).then(res => res.data).catch(http.defaultCatch);
    handleBlobResponse(response, (data.Model ? `${data.Model}.xlsx` : `${group}.xlsx`));
}


const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const uploaDataRequest =  async (data) => {
    data.File = await toBase64(data.File);
    return await http.post(`${group}/uploadData`, data).then(res => res.data).catch(http.defaultCatch);
}